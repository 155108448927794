/* You can add global styles to this file, and also import other style files */
@import "@progress/kendo-theme-material/scss/grid/_index.scss";
@import "~@angular/material/theming";
@import "src/OEtheme.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

div.tooltip {
  font-size: 13px;
  font-weight: 500;
  white-space: pre-wrap;
}

:root {
  --background-light-gray: rgb(244, 244, 244);
}

.pac-search-grid .k-grid-header {
  padding: 0 !important;
}

.pac-search-grid .k-grid-content {
  overflow-y: visible;
}
